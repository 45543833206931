<template>
<div class="apprMind" v-if="tableData.length>0 || nodeName!='开始'">
    <div class="header">审批详情
      <span class="showOff">
        <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
          <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
        </el-button>
      </span>
    </div>
    <el-form v-if="!isclose" ref="form" class="disabled content bascform" :disabled="true" label-width="120px">
      <div class="col col4">
        <el-form-item label="当前环节:">
            <span class="onlyText">{{nodeName}}</span>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="审批人:">
            <span class="onlyText">{{apprPsn}}</span>
        </el-form-item>
      </div>
      <div class="col col8" v-if="tableData.length>0">
        <global-table :tableField="tableField" :tableData="tableData"></global-table>
      </div>
    </el-form>
</div>
</template>

<script>
import { FlowCtlData } from '@/assets/js/FlowManage.js'
import Component from '@/assets/js/components.js'
export default {
  name: 'ApprovalMind',
  components: Component.components,
  data: function () {
    FlowCtlData.setFlowDetai = this.formatMind
    FlowCtlData.setThisFlowInfo = this.thisFlowInfo
    return {
      tableField: [
        { label: '节点名称', code: 'NODENAME', type: 'input', width: '' },
        { label: '处理人', code: 'ACTIONPSNID', type: 'input', width: '' },
        { label: '处理时间', code: 'CREATDT', type: 'input', width: '' },
        { label: '审批意见', code: 'APPROVEMIND', type: 'input', width: '' }
      ],
      tableData: [],
      nodeName: '',
      apprPsn: '',
      isclose: true
    }
  },
  created () {
    this.initialized()
  },
  methods: {
    initialized () {
      if (FlowCtlData.initialized !== 5) {
        setTimeout(() => { this.initialized() }, 1000)
      } else {
        this.formatMind(FlowCtlData.flowAppDetail)
        this.thisFlowInfo(FlowCtlData.nodeThisData)
      }
    },
    open () {
      this.isclose = !this.isclose
    },
    thisFlowInfo (data) {
      if (data) {
        this.nodeName = data[0].NODENAME
        this.apprPsn = data[0].APPROVER
      }
    },
    formatMind (DetailData) {
      var tmpflowdet = JSON.parse(JSON.stringify(DetailData))
      var tdfghtra = []
      for (const item of tmpflowdet) {
        var PRVEACTIONID = item.PRVEACTIONID
        var tmpdata = JSON.parse(JSON.stringify(item))
        if (PRVEACTIONID !== undefined && PRVEACTIONID !== null && PRVEACTIONID !== 'null') {
          for (const item1 of tmpflowdet) {
            if (item1.ACTIONID === PRVEACTIONID) {
              tmpdata.NODENAME = item1.NODENAME
              tdfghtra.push(tmpdata)
            }
          }
        }
      }
      for (const item of tdfghtra) {
        item.APPROVEMIND = FlowCtlData.base64.decode(item.APPROVEMIND)
      }
      this.tableData = tdfghtra
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '@/assets/css/elForm.scss';
  .apprMind{
      :deep(.tableList){
          padding:0
      }
    .showOff{
        float: right;
    }
  }
  .col8{
    width: 1200px;
  }
</style>
